import React, { useState, useEffect } from "react";
import { Card, Form, Col, Row } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { getDataPracticalAPI } from "../../api"; // Ensure this path is correct
import InterviewPracticalChange from "./InterviewPracticalchange";
import Loader from "../Widgets/Loader/Loader";

const Practical = () => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [dataPerPageButton, setDataPerPageButton] = useState("10");
  // const [originalData, setOriginalData] = useState([]);
  // const [currentPageData, setCurrentPageData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);




  

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const visiblePages = 5;
  const totalPages = Math.ceil(originalData.length / dataPerPageButton);
  // const startPage = 1;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));


  const getPracticalFunction = async () => {
    try {
      setLoading(true);
      const response = await getDataPracticalAPI();
      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true &&
        response.data.response.data
      ) {
        const PracticalData = response.data.response.data;
        setOriginalData(PracticalData);
        setCurrentPageData(PracticalData.slice(0, parseInt(dataPerPageButton)));
      } else {
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getPracticalFunction();
  }, []);


  const handleFirst = () => {
    setCurrentPage(1);
  };
  
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handleLast = () => {
    setCurrentPage(Math.ceil(originalData.length / dataPerPageButton));
  };
  

  useEffect(() => {
    // Calculate index of the last item on the current page
    const indexOfLastItem = currentPage * dataPerPageButton;
    // Calculate index of the first item on the current page
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    // Slice the original data array to get current page's data
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    // Update current page data state
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);



  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);



  // useEffect(() => {
  //   if (searchQuery === "") {
  //     setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
  //   } else {
  //     const filteredData = originalData.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );
  //     setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
  //   }
  // }, [searchQuery, dataPerPageButton, originalData]);




  

  useEffect(() => {
    const startIndex = (currentPage - 1) * dataPerPageButton;
    const endIndex = currentPage * dataPerPageButton;
  
    // Filter the original data based on the search query
    let filteredData = originalData;
    if (searchQuery !== "") {
      filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  
    // Slice the filtered data based on the current page
    const currentPageData = filteredData.slice(startIndex, endIndex);
  
    setCurrentPageData(currentPageData);
  }, [searchQuery, dataPerPageButton, originalData, currentPage]);

  

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = (item) => {
    setShowModal(true);
    setSelectedItem(item);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="main_desktop">
        <Card.Body>
          <Row className="my-2 mx-2 align-items-center">
            <Col lg="2" className="colIn dataTable_practical">
       <div style={{ position: 'relative', display: 'inline-block', width: '60px' }}>
  <select
        value={dataPerPageButton}
        onChange={handleDataPerPageChange}
    style={{
      width: '100%',
      padding: '6px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      appearance: 'none', // Remove default arrow
      WebkitAppearance: 'none', // Safari compatibility
      MozAppearance: 'none', // Firefox compatibility
      backgroundColor: 'white',
      cursor: 'pointer'
    }}
  >
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
  </select>
  <FaCaretDown
    style={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none', // Prevent interaction with the icon
      fontSize: '14px',
      color: '#333'
    }}
  />
</div>
            </Col>
            <Col lg="10" className="search_practical">
              <Row className="justify-content-end align-items-center">
                <Col lg="9" className="sear">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="searchInput"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div id="table_wrapper" className="dataTables_wrapper no-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive table-wrapper">
                    <table className="table table-striped table-hover dataTable no-footer">
                      <thead>
                        <tr style={{ cursor: "default" }}>
                          <th scope="col" className="col-1 th_font_size">
                            Sr.No.
                          </th>
                          <th scope="col" className="col-2 th_font_size">
                            City
                          </th>
                          <th scope="col" className="col-2 th_font_size">
                            Country
                          </th>
                          <th scope="col" className="col-2 th_font_size">
                            Venue
                          </th>
                          <th scope="col" className="col-4 th_font_size">
                            Address
                          </th>
                          <th scope="col" className="col-1 th_font_size">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPageData
                          .slice(0, parseInt(dataPerPageButton))
                          .map((item, index) => (
                            <tr
                              key={index}
                              className={index % 2 === 0 ? "even" : "odd"}
                            >
                              <td className="td_font_size">{index + 1}.</td>
                              <td className="td_font_size">
                                {item?.city_name}
                              </td>
                              <td className="td_font_size">{item?.country}</td>
                              <td className="td_font_size">{item?.venue}</td>
                              <td className="td_font_size">
                                {item?.office_address}
                              </td>
                              <td className="td_font_size">
                                <div className="btn-container">
                                  <button
                                    className="btn btn-sm edit"
                                    style={{
                                      backgroundColor: "#055837",
                                      color: "#ffcc01",
                                      marginRight: "8px",
                                      transition:
                                        "background-color 0.2s, color 0.2s",
                                    }}
                                    onClick={() => handleShow(item?.id)}
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-3 thick-hr" />
            <div className="pagination-container">
  <div className="dataTables_info">
    Showing{" "}
    {currentPage === 1
      ? 1
      : (currentPage - 1) * dataPerPageButton + 1}{" "}
    to{" "}
    {Math.min(currentPage * dataPerPageButton, originalData.length)}{" "}
    of {originalData.length} entries
  </div>

  {/* <span 
    style={{
      background: 'linear-gradient(to bottom right, #fff9c4, #fff3e0)', 
      color: '#055837', 
      padding: '8px 12px',
      borderRadius: '5px',
      display: 'inline-block',
      cursor: "default"
    }}
  >
    Active Page : {currentPage}
  </span> */}

  <div className="pagination-buttons">
    <button
      className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
      onClick={handleFirst}
      disabled={currentPage === 1}
    >
      First
    </button>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={`paginate_button ${startPage === 1 ? "disabled" : ""}`}
        onClick={handlePrev}
        disabled={startPage === 1}
      >
        Previous
      </button>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
        {Array.from({ length: visiblePages }, (_, index) => {
          const pageNumber = startPage + index;
          if (pageNumber > totalPages) return null;

          const isActive = currentPage === pageNumber;

          return (
            <button
              key={pageNumber}
              className={`paginate_button ${isActive ? "active" : ""}`}
              style={{
                display: 'inline-block',
                margin: '0 4px',
                backgroundColor: isActive ? "black" : "transparent",
                color: isActive ? "white" : "black",
              }}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        className={`paginate_button ${startPage + visiblePages - 1 >= totalPages ? "disabled" : ""}`}
        onClick={handleNext}
        disabled={startPage + visiblePages - 1 >= totalPages}
      >
        Next
      </button>
    </div>

    <button
      className={`paginate_button ${currentPage === totalPages ? "disabled" : ""}`}
      onClick={handleLast}
      disabled={currentPage === totalPages}
    >
      Last
    </button>
  </div>
</div>
          </div>
        </Card.Body>
      </div>

      <InterviewPracticalChange
        showModal={showModal}
        handleClose={handleClose}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        refreshData={getPracticalFunction}
      />
    </>
  );
};

export default Practical;
