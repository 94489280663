import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import "./MailTemplate.css";
import { Link, useNavigate } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import { getMailTemplateDataAPI } from "../../api";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../Widgets/Loader/Loader";

const MailTemplate = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const visiblePages = 5;
  const totalPages = Math.ceil(originalData.length / dataPerPageButton);
  // const startPage = 1;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  const getMailTemplateDataFunction = async () => {
    try {
      setLoading(true);
      const response = await getMailTemplateDataAPI();

      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const mailTemplateData = response?.data?.response?.data;

        setOriginalData(mailTemplateData);
        setCurrentPageData(
          mailTemplateData.slice(0, parseInt(dataPerPageButton))
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getMailTemplateDataFunction();
  }, []);


  
  const handleFirst = () => {
    setCurrentPage(1);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(originalData.length / dataPerPageButton));
  };

  useEffect(() => {
    // Calculate index of the last item on the current page
    const indexOfLastItem = currentPage * dataPerPageButton;
    // Calculate index of the first item on the current page
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    // Slice the original data array to get current page's data
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    // Update current page data state
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  // useEffect(() => {
  //   if (searchQuery === "") {
  //     setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
  //   } else {
  //     const filteredData = originalData.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );
  //     setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
  //   }
  // }, [searchQuery, dataPerPageButton, originalData]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * dataPerPageButton;
    const endIndex = currentPage * dataPerPageButton;

    // Filter the original data based on the search query
    let filteredData = originalData;
    if (searchQuery !== "") {
      filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    // Slice the filtered data based on the current page
    const currentPageData = filteredData.slice(startIndex, endIndex);

    setCurrentPageData(currentPageData);
  }, [searchQuery, dataPerPageButton, originalData, currentPage]);


  
  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();
    navigate(`/admin/mail_template/edit`, {
      state: { id: id },
    });
  };


  // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/mail_template/name_keywords/edit`, {
  //     state: { id: id },
  //   });
  // };


  return (
    <>
      <div className="main_desktop mb-5">
        <hr className="mb-2 thick-hr" />

        {loading && <Loader />}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Mail Template
              </h3>
            </div>
            <div className="main-body-area">
              <Card className="shadow">
                <Card.Body>
                  <div className="action_buttons_top bg-white disdeskm">
                    <Row className="my-2 mx-2 align-items-center">
                      <Col lg="2" className="my-auto datalen">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "60px",
                          }}
                        >
                          <select
                            value={dataPerPageButton}
                            onChange={handleDataPerPageChange}
                            style={{
                              width: "100%",
                              padding: "6px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                              appearance: "none", // Remove default arrow
                              WebkitAppearance: "none", // Safari compatibility
                              MozAppearance: "none", // Firefox compatibility
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <FaCaretDown
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translateY(-50%)",
                              pointerEvents: "none", // Prevent interaction with the icon
                              fontSize: "14px",
                              color: "#333",
                            }}
                          />
                        </div>
                      </Col>
                      <Col
                        lg="2"
                        className="my-auto text-end d-flex justify-content-start"
                      >
                        <Link
                          to="/admin/mail_template/add_mail"
                          className="btn btn_green_yellow "
                        >
                          + Add Mail
                        </Link>
                      </Col>

                      <Col lg="6">
                        <Row className="justify-content-center align-items-center">
                          <Col lg="9">
                            <Form.Control
                              type="search"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearch}
                              className="searchInput"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="my-auto text-end d-flex justify-content-end name-key">
                        <Link
                          to="/admin/mail_template/name_keywords"
                          className="btn btn_green_yellow "
                        >
                          + Name Keywords
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div class="table-responsive table-wrapper">
                            <table
                              class="table table-striped table-hover dataTable no-footer"
                              style={{ cursor: "default", overflowX: "auto" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ width: "8%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Sr. No.
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "5%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    IDs
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "25%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Name
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "20%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Keyword
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ width: "40%", fontSize: "14px" }} // Adjust percentage and font size as needed
                                  >
                                    Subject
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {currentPageData
                                  .slice(0, parseInt(dataPerPageButton))
                                  .map((item, index) => (
                                    <tr
                                      key={index}
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                    >
                                      <td className="td_font_size">
                                        {index + 1}.
                                      </td>
                                      <td className="td_font_size">
                                        {item?.id}
                                      </td>

                                      <td
                                        onClick={(event) =>
                                          handleNavigateToViewApplication(
                                            event,
                                            item?.id
                                          )
                                        }
                                        style={{
                                          // color: "#055837",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        className="hover-underline"
                                      >
                                        {item?.name}
                                      </td>

                                      <td className="td_font_size">
                                        {item?.name_keyword}
                                      </td>
                                      <td className="td_font_size">
                                        {item?.subject}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="mb-3 thick-hr" />
                    <div className="pagination-container">
                 <div className="dataTables_info">
  Showing{" "}
  {originalData.length > 0
    ? (
        currentPage === 1
          ? 1
          : (currentPage - 1) * dataPerPageButton + 1
      )
    : 0}{" "}
  to{" "}
  {originalData.length > 0
    ? Math.min(
        currentPage * dataPerPageButton,
        originalData.length
      )
    : 0}{" "}
  of {originalData.length} entries
</div>


                      {/* <span 
    style={{
      background: 'linear-gradient(to bottom right, #fff9c4, #fff3e0)', 
      color: '#055837', 
      padding: '8px 12px',
      borderRadius: '5px',
      display: 'inline-block',
      cursor: "default"
    }}
  >
    Active Page : {currentPage}
  </span> */}

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handleFirst}
                          disabled={currentPage === 1}
                        >
                          First
                        </button>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            className={`paginate_button ${
                              startPage === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrev}
                            disabled={startPage === 1}
                          >
                            Previous
                          </button>

                          <div
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              flex: 1,
                            }}
                          >
                            {Array.from(
                              { length: visiblePages },
                              (_, index) => {
                                const pageNumber = startPage + index;
                                if (pageNumber > totalPages) return null;

                                const isActive = currentPage === pageNumber;

                                return (
                                  <button
                                    key={pageNumber}
                                    className={`paginate_button ${
                                      isActive ? "active" : ""
                                    }`}
                                    style={{
                                      display: "inline-block",
                                      margin: "0 4px",
                                      backgroundColor: isActive
                                        ? "black"
                                        : "transparent",
                                      color: isActive ? "white" : "black",
                                    }}
                                    onClick={() => handlePageClick(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                );
                              }
                            )}
                          </div>

                          <button
                            className={`paginate_button ${
                              startPage + visiblePages - 1 >= totalPages
                                ? "disabled"
                                : ""
                            }`}
                            onClick={handleNext}
                            disabled={
                              startPage + visiblePages - 1 >= totalPages
                            }
                          >
                            Next
                          </button>
                        </div>

                        <button
                          className={`paginate_button ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                          onClick={handleLast}
                          disabled={currentPage === totalPages}
                        >
                          Last
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailTemplate;
