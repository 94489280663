import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  getZoomDetailsReassesmentbyIdAPI,
  postSubmitReassesmentZoomDetailsAPI,
} from "../../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerificationModal from "../VerificationModal/VerificationModal";
import Loader from "../../../Widgets/Loader/Loader";

const ZoomDetails = ({
  showModal,
  handleClose,
  selectedItem,
  fetchPracticalBookingData,
}) => {
  const [loading, setLoading] = useState(false);

  const [showDeleteAccountModal, setDeleteAccountShow] = useState(false);
  const handleDeleteAccountClose = () => setDeleteAccountShow(false);
  const handleDeleteAccountShow = () => {
    setDeleteAccountShow(true);
  };

  const [headText, setHeadText] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [passcode, setPasscode] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [SelectedVenue, setSelectedVenue] = useState("");
  const [meetingEmail, setMeetingEmail] = useState("");
  const [meetingCcEmail, setMeetingCcEmail] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [stage3InterviewBookingId, setStage3InterviewBookingId] = useState("");
  const [emailInterviewLocationId, setEmailInterviewLocationId] = useState("");

  const clearAllStates = () => {
    setHeadText("");
    setMeetingId("");
    setPasscode("");
    setSelectedDate("");
    setSelectedTime("");
    setSelectedVenue("");
    setMeetingEmail("");
    setMeetingCcEmail("");
    setSelectedZone("");
    setStage3InterviewBookingId("");
    setEmailInterviewLocationId("");
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const [day, month, year] = dateString.split("-");

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[parseInt(month) - 1];
    return `${day} ${monthName} ${year}`;
  };
  // Fetch zoom details
  const getSingleZoomDetailFunction = async (selectedItem) => {
    try {
      setLoading(true);
      const response = await getZoomDetailsReassesmentbyIdAPI(selectedItem?.id);
      setLoading(false);
      if (
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setHeadText(response?.data?.response?.data?.heading_title || "");

        const booking_form_details = response.data.response.data.booking_form;

        setMeetingId(booking_form_details?.meeting_id || "");
        setPasscode(booking_form_details?.passcode || "");

        setStage3InterviewBookingId(
          booking_form_details?.stage_3_interview_booking_id
        );
        setSelectedDate(formatDate(booking_form_details?.date || ""));
        setSelectedTime(booking_form_details?.time || "");
        setSelectedVenue(booking_form_details?.venue?.name || "");
        setMeetingEmail(booking_form_details?.email || "");
        setMeetingCcEmail(booking_form_details?.email_cc || []);
        setEmailInterviewLocationId(
          booking_form_details?.email_interview_location_id || ""
        );
        setSelectedZone(booking_form_details?.applicant_location);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching zoom details:", error);
    }
  };

  const handleSubmitCheck = () => {
    if (!headText) {
      toast.error("Please fill in the Head Text field.");
      return;
    }

    if (!meetingId) {
      toast.error("Please fill in the Meeting ID field.");
      return;
    }

    if (!passcode) {
      toast.error("Please fill in the Passcode field.");
      return;
    }

    if (!selectedDate) {
      toast.error("Please select a Date.");
      return;
    }

    if (!selectedTime) {
      toast.error("Please select a Time.");
      return;
    }

    if (!SelectedVenue) {
      toast.error("Please fill in the SelectedVenue field.");
      return;
    }

    if (!meetingEmail) {
      toast.error("Please fill in the Meeting Email field.");
      return;
    }

    // if (!meetingCcEmail) {
    //   toast.error("Please fill in the CC Email field.");
    //   return;
    // }

    handleDeleteAccountShow();
  };

  const submitZoomDetailsFunction = async () => {
    if (!headText) {
      toast.error("Please fill in the Head Text field.");
      return;
    }

    if (!meetingId) {
      toast.error("Please fill in the Meeting ID field.");
      return;
    }

    if (!passcode) {
      toast.error("Please fill in the Passcode field.");
      return;
    }

    if (!selectedDate) {
      toast.error("Please select a Date.");
      return;
    }

    if (!selectedTime) {
      toast.error("Please select a Time.");
      return;
    }

    if (!SelectedVenue) {
      toast.error("Please fill in the SelectedVenue field.");
      return;
    }

    if (!meetingEmail) {
      toast.error("Please fill in the Meeting Email field.");
      return;
    }

    // if (!meetingCcEmail) {
    //   toast.error("Please fill in the CC Email field.");
    //   return;
    // }

    try {
      const form = new FormData();
      form.append("meeting_id", meetingId);
      form.append("passcode", passcode);
      form.append("stage_3_interview_booking_id", stage3InterviewBookingId);
      form.append("pointer_id", selectedItem?.pointer_id);
      form.append("email_interview_location_id", emailInterviewLocationId);
      form.append("venue", SelectedVenue);
      form.append("date", selectedDate);
      form.append("time", selectedTime);
      form.append("time_zone", selectedZone);
      form.append("applicant_name", headText);
      form.append("id", selectedItem?.id);

      setLoading(true);
      const response = await postSubmitReassesmentZoomDetailsAPI(form);
      setLoading(false);
      if (
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg ||
            "Zoom Meeting fixed successfully."
        );
        fetchPracticalBookingData();
        handleClose();
        handleDeleteAccountClose();
        clearAllStates();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Unknown Error.");
      console.error("Error while submitting zoom details:", error);
      clearAllStates();
      fetchPracticalBookingData();
      handleClose();
      handleDeleteAccountClose();
    }
  };

  useEffect(() => {
    if (selectedItem && showModal) {
      getSingleZoomDetailFunction(selectedItem);
    }
  }, [selectedItem,showModal]);

  const handleZoneChange = (event) => {
    setSelectedZone(event.target.value);
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "meetingId":
        setMeetingId(value);
        break;
      case "passcode":
        setPasscode(value);
        break;
      case "selectedDate":
        setSelectedDate(value);
        break;
      case "selectedTime":
        setSelectedTime(value);
        break;
      case "meetingEmail":
        setMeetingEmail(value);
        break;
      case "meetingCcEmail":
        setMeetingCcEmail(value);
        break;
      default:
        break;
    }
  };
  const handleFakeSubmit = async (event) => {
    event.preventDefault();
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        show={showModal}
        onHide={() => {
          handleClose();
          clearAllStates();
        }}
        animation={false}
        centered
      >
        <form onSubmit={handleFakeSubmit}>
          <Modal.Header>
            <Modal.Title className="modal-title text-center text-green">
              <h5>Zoom Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h5 className="modal-title text-center text-green">{headText}</h5>

            <div className="zoom_details">
              <label
                htmlFor="venue-select"
                className="form-label label-font-size"
              >
                <b>
                  Venue <span className="text-danger">*</span>
                </b>
              </label>
              <select
                name="SelectedVenue"
                id="venue-select"
                className="form-select"
                value={SelectedVenue}
                disabled
                required
                onChange={handleChange}
              >
                <option value={SelectedVenue}>{SelectedVenue}</option>
              </select>
            </div>

            {selectedZone && (
              <div className="form-group">
                <label
                  htmlFor="AppLocation"
                  className="form-label label-font-size"
                >
                  <b>
                    Applicant Location <span className="text-danger">*</span>
                  </b>
                </label>

                <select
                  name="applicantLocation"
                  id="AppLocationZone"
                  className="form-select md-4"
                  value={selectedZone}
                  onChange={handleZoneChange}
                  required
                  disabled
                  style={{ cursor: "pointer" }}
                >
                  <option value={selectedZone}>{selectedZone}</option>
                </select>
              </div>
            )}

            <div className="zoom_details">
              <label
                htmlFor="selectedDate"
                className="form-label label-font-size"
              >
                <b>
                  Date <span className="text-danger">*</span>
                </b>
              </label>
              <input
                name="selectedDate"
                type="text"
                className="form-control"
                value={selectedDate}
                onChange={handleChange}
                disabled
                required
              />
            </div>

            <div className="zoom_details">
              <label htmlFor="selectedTime" className="form-label">
                <b>
                  Time (QLD) <span className="text-danger">*</span>
                </b>
              </label>
              <select
                name="selectedTime"
                id="time-select"
                className="form-select"
                value={selectedTime}
                disabled
                required
              >
                <option value={selectedTime}>{selectedTime}</option>
              </select>
            </div>

            <div className="zoom_details">
              <label htmlFor="meetingId" className="form-label">
                <b>
                  Meeting ID <span className="text-danger">*</span>
                </b>
              </label>
              <input
                name="meetingId"
                type="text"
                className="form-control"
                value={meetingId}
                onChange={handleChange}
                required
              />
            </div>

            <div className="zoom_details">
              <label htmlFor="passcode" className="form-label">
                <b>
                  Passcode <span className="text-danger">*</span>
                </b>
              </label>
              <input
                name="passcode"
              type="text"
                className="form-control"
                value={passcode}
                onChange={handleChange}
                required
              />
            </div>

            <div className="zoom_details">
              <label htmlFor="meetingEmail" className="form-label">
                <b>
                  Email Id <span className="text-danger">*</span>
                </b>
              </label>
              <input
                name="meetingEmail"
                type="email"
                className="form-control"
                value={meetingEmail}
                onChange={handleChange}
                disabled
                required
              />
            </div>

            {meetingCcEmail && meetingCcEmail.length > 0 && (
              <div className="zoom_details">
                <label htmlFor="meetingCcEmail" className="form-label">
                  <b>CC-Email Id</b>
                </label>
                {meetingCcEmail.map((email, index) => (
                  <input
                    key={index}
                    name={`meetingCcEmail-${index}`}
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={handleChange}
                    disabled
                    required
                    style={{marginBottom:"5px"}}
                  />
                ))}
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn_yellow_green"
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="btn_modal"
              variant="primary"
              // onClick={submitZoomDetailsFunction}
              onClick={handleSubmitCheck}
              style={{
                padding: "7px 20px",
                borderRadius: "6px",
                border: "none",
              }}
              type="submit"
            >
              Save & Reschedule
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {showDeleteAccountModal && (
        <VerificationModal
          show={showDeleteAccountModal}
          onClose={handleDeleteAccountClose && clearAllStates}
          onYes={submitZoomDetailsFunction}
          Title="Confirm Zoom Meeting!"
          Message={
            <>
              Are you sure you want to{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                &nbsp; Zoom Meeting
              </span>
              &nbsp; ?
            </>
          }
        />
      )}
    </>
  );
};

export default ZoomDetails;
