import React, { useState, useRef, useEffect } from "react";
import "../MailTemplate.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SidebarMenu from "../../Widgets/SideBar Menu/SidebarMenu";
import { Link, useNavigate } from "react-router-dom";
import "./AddMail.css";
import { addMailTemplate, getMailTemplateDropdownOptions } from "../../../api";
import Loader from "../../Widgets/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddMail = () => {
  const [editorContent, setEditorContent] = useState("");
  const [templateName, setTemplateName] = useState("");

  const [templateNames, setTemplateNames] = useState([]);

  const getPopUpDataFunction = async () => {
    try {
      setLoading(true);

      const response = await getMailTemplateDropdownOptions();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setTemplateNames(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching records:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching records:", error);
    }
  };

  useEffect(() => {
    getPopUpDataFunction();
  }, []);

  const [subject, setSubject] = useState("");
  const quillRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const insertTextAtCursor = (text) => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);
    editor.focus(); // Focus the editor
    editor.insertText(range.index, text);
    editor.setSelection(range.index + text.length);
  };

  const keywords = [
    "%personal_details_first_name%",
    "%personal_details_middle_name%",
    "%personal_details_surname_name%",
    "%occupation_occupation%",
    "%occupation_program%",
    "%pathway%",
    "%email_first_name%",
    "%email_surname_name%",
    "%unique_id%",
    "%email_signature%",
    "%occupation_reason_for_decline%",
    "%reason_for_decline%",
    "%day_date%",
    "%get_slot_time%",
    "%skill_title%",
    "%add_employment_referee_name%",
    "%add_employment_company_name%",
    "%s2_reason_for_decline%",
    "%s3_interview_day_and_date%",
    "%s3_interview_time%",
    "%s3_interview_venue%",
    "%s3_interview_address%",
    "%Expiry_Date%",
  ];

  AddMail.modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  AddMail.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    const handleClick = () => {
      const range = editor.getSelection();
      if (!range) {
        // Set the cursor position to the end if there's no selection
        editor.focus();
        editor.setSelection(editor.getLength(), 0);
      }
    };

    const quillEditorContainer = quillRef.current.editor.container;
    quillEditorContainer.addEventListener("click", handleClick);

    // Clean up event listener on unmount
    return () => {
      quillEditorContainer.removeEventListener("click", handleClick);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate fields
    if (!templateName.trim()) {
      toast.error("Please select a template name.");
      setLoading(false);
      return;
    }

    if (!subject.trim()) {
      toast.error("Please fill in the subject.");
      setLoading(false);
      return;
    }

    if (!editorContent.trim()) {
      toast.error("Please fill in the email body.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name_keyword_id", templateName);
      formData.append("subject", subject);
      formData.append("body", editorContent);

      setLoading(true);

      const response = await addMailTemplate(formData);

      setLoading(false);

      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.response === true
      ) {
        toast.success("Mail Template Added Successfully.");
        navigate("/admin/mail_template");
        setTemplateName("");
        setSubject("");
        setEditorContent("");
      } else {
        console.error(
          "Error updating location data:",
          response?.data?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in saving.");
      }
    } catch (error) {
      console.log("Error ", error);

      setLoading(false);
    } finally {
      setTemplateName("");
      setSubject("");
      setEditorContent("");
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div>
        <hr className="mb-2 thick-hr" />
        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>
          <div className="sub-wrapper-main mb-5 addmail_wrapper">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Add Mail Template
              </h3>
            </div>
            <div className="card shadow">
              <div className="card-body">
                <form id="edit_form" onSubmit={handleSubmit}>
                  <div className="row g-0 card-header">
                    <div className="col-2 col-button1">
                      <button type="submit" className="btn btn_green_yellow">
                        Add Template
                      </button>
                    </div>
                    <div className="col-10 col-button2">
                      <Link
                        to="/admin/mail_template"
                        className="btn btn_yellow_green float-end"
                      >
                        <i className="bi bi-arrow-left "></i>
                        Back
                      </Link>
                    </div>
                  </div>
                  <div className="row card-body col_addmail">
                    <div className="col-8 column_first">
                      <div className="row my-2">
                        <div className="col-12">
                          <label>Name</label>
                          <select
                            name="template_name"
                            className="form-select"
                            id="template_id"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            style={{ cursor: "pointer" }}
                          >
                            <option value="">--Select Name--</option>
                            {templateNames.map((template) => (
                              <option key={template.id} value={template.id}>
                                {template.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row g-0 my-2">
                        <div className="col-12">
                          <label htmlFor="subjectField">Subject</label>
                          <input
                            type="text"
                            name="subject"
                            className="form-control subject"
                            id="subjectField"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-0 my-2">
                        <div className="col-12">
                          <label htmlFor="quill">Body</label>
                        </div>
                        <div className="quill">
                          <ReactQuill
                            ref={quillRef}
                            value={editorContent}
                            onChange={handleEditorChange}
                            modules={AddMail.modules}
                            formats={AddMail.formats}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                    <div className="col-4 text-center column_second">
                      <div className="keyword-wrapper">
                        {keywords.map((keyword, index) => (
                          <div
                            key={index}
                            className="keyword border my-2 rounded"
                          >
                            <input
                              type="button"
                              value={keyword}
                              className="btn input_btns btn_yg"
                              onClick={() => insertTextAtCursor(keyword)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMail;
