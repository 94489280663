import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import DynamicTitle from "./DynamicTitle";

import InternetChecker from "./components/Widgets/Internet Checker/Internet Checker";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ForgotPassword from "./components/Forgot Password/Forgot Password";
import Dashboard from "./components/Dashboard/Dashboard";
import ApplicationManager from "./components/Application Manager/Application Manager";
import ViewUpdate from "./components/Application Manager/View Update/View_Update";
import Login from "./components/Login/Login";
import ResetPassword from "./components/Reset Password/Reset Password";
import InterviewBookings from "./components/Interview Bookings/Interview Bookings";
import PracticalBookings from "./components/Practical Bookings/Practical Bookings";
import ApplicantAgent from "./components/Applicant_Agent/Applicant_Agent";
import OccupationManager from "./components/Occupation Manager/Occupation Manager";
import Verification from "./components/Verification/Verification";
import Archive from "./components/Archive/Archive";
import MailTemplate from "./components/MailTemplate/MailTemplate";
import OfflineFiles from "./components/Offline Files/Offline Files";
import AdminFunctions from "./components/Admin Functions/Admin Functions";
import Locations from "./components/Locations/Locations";
import RefereeDetails from "./components/Verification/Employment/RefereeDetails/RefereeDetails";
import RTODetails from "./components/Verification/Qualification/RTODetails/RTODetails";
import AddMail from "./components/MailTemplate/Add Mail/AddMail";
import NameKeywords from "./components/MailTemplate/Name Keywords/NameKeywords";
import EditMail from "./components/MailTemplate/EditMail/EditMail";
import ApplicantTransfer from "./components/Application Manager/Common Module/ApplicantTransfer/ApplicantTransfer";
import ApplicantTransferPreview from "./components/Application Manager/Common Module/ApplicantTransfer/ApplicantTransferPreview";
import StaffManagement from "./components/Staff Management/Staff Management";
import Company from "./components/Applicant_Agent/Company/Company";
import AgentApplicantForm from "./components/Applicant_Agent/Form/Form";
import { getAllViewUpdateOfAppManager } from "./api";
import AutoLogoutChecker from "./components/AutoLogoutChecker/AutoLogoutChecker";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isAQATO_adminLoggedIn");
    const encryptedToken = localStorage.getItem("encryptedTokenForAQATO_Admin");

    if (isLoggedIn === "true" && encryptedToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      // handleLogout();
    }
  }, []);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem("isAQATO_adminLoggedIn");
    localStorage.removeItem("encryptedTokenForAQATO_Admin");
    localStorage.removeItem("AQATO_SuperAdminName");
    localStorage.clear();
    localStorage.clear();
  };

  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOffline = () => setIsOffline(true);
    const handleOnline = () => setIsOffline(false);

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const PrivateRoute = ({ element: Element, loggedIn, onLogout, title }) => {
    const location = useLocation();
    const [currentViewingApplicantName, setCurrentViewingApplicantName] =
      useState("");
    const [pointerID, setPointerID] = useState(null);

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get("pointerID");
      setPointerID(id);
    }, [location.search]);

    useEffect(() => {
      const fetchApplicantName = async () => {
        if (!pointerID) return; // Return early if no pointerID is present

        try {
          const response = await getAllViewUpdateOfAppManager(pointerID);

          if (
            response &&
            response?.data &&
            response?.data?.response &&
            response?.data?.response?.response === true &&
            response?.data?.response?.data
          ) {
            const applicantName =
              response?.data?.response?.data?.profile_data?.applicant_name;
            setCurrentViewingApplicantName(applicantName);
          } else {
            console.error(
              "Error fetching data:",
              response?.data?.error_msg || "Unknown error"
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      if (location.pathname === "/admin/application_manager/view_application") {
        fetchApplicantName();
      } else {
        setCurrentViewingApplicantName("");
      }
    }, [location.pathname, pointerID]);

    const dynamicTitle =
      location.pathname === "/admin/application_manager/view_application"
        ? `${currentViewingApplicantName} - ${title}`
        : title;

    return (
      <>
        <DynamicTitle title={dynamicTitle} />
        {loggedIn ? <Element onLogout={onLogout} /> : ""}
      </>
    );
  };




  return (
    <>
      <div className="App">
        <BrowserRouter>
          {isOffline && <InternetChecker />}
          <ScrollToTop />

{/* 
{!loggedIn && (
          <AutoLogoutChecker 
        onLogout={handleLogout} 
        loggedIn={loggedIn} 
      />
)} */}



          <Header onLogout={handleLogout} />

          <Routes>



            <Route
              path="/"
              element={
                <>
                  <DynamicTitle title="Login - AQATO Admin Portal" />
                  {loggedIn ? (
                    <Navigate to="/admin/dashboard" />
                  ) : (
                    <Login onLogin={handleLogin} />
                  )}
                </>
              }
            />

            <Route
              path="/admin/forgot_password"
              element={
                <>
                  <DynamicTitle title="Forgot Password - AQATO Admin Portal" />
                  {loggedIn ? (
                    <Navigate to="/admin/dashboard" />
                  ) : (
                    <ForgotPassword />
                  )}
                </>
              }
            />
            <Route
              path="/admin/reset_password"
              element={
                <>
                  <DynamicTitle title="Reset Password - AQATO Admin Portal" />
                  {loggedIn ? (
                    <Navigate to="/admin/dashboard" />
                  ) : (
                    <ResetPassword />
                  )}
                </>
              }
            />


            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute
                  element={Dashboard}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Dashboard - AQATO Admin Portal"
                />
              }
            />

            <Route
              path="/admin/application_manager"
              element={
                <PrivateRoute
                  element={ApplicationManager}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Application Manager - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/interview_bookings"
              element={
                <PrivateRoute
                  element={InterviewBookings}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Interview Bookings - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/practical_bookings"
              element={
                <PrivateRoute
                  element={PracticalBookings}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Practical Bookings - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/applicant_agent"
              element={
                <PrivateRoute
                  element={ApplicantAgent}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Applicant/Agent - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/staff_management"
              element={
                <PrivateRoute
                  element={StaffManagement}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Staff Management - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/occupation_manager"
              element={
                <PrivateRoute
                  element={OccupationManager}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Occupation Manager - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/verification"
              element={
                <PrivateRoute
                  element={Verification}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Verification - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/archive"
              element={
                <PrivateRoute
                  element={Archive}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Archive - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/mail_template"
              element={
                <PrivateRoute
                  element={MailTemplate}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Mail Template - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/mail_template/add_mail"
              element={
                <PrivateRoute
                  element={AddMail}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Add Mail Template - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/mail_template/name_keywords"
              element={
                <PrivateRoute
                  element={NameKeywords}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Mail Template Name Keywords - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/mail_template/edit"
              element={
                <PrivateRoute
                  element={EditMail}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Edit Mail Template - AQATO Admin Portal"
                />
              }
            />
            {/* <Route
              path="/admin/mail_template/name_keywords/edit"
              element={
                <PrivateRoute
                  element={EditMailNameKey}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Edit Name Keywords - AQATO Admin Portal"
                />
              }
            /> */}


            <Route
              path="/admin/offline_files"
              element={
                <PrivateRoute
                  element={OfflineFiles}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Offline Files - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/admin_functions"
              element={
                <PrivateRoute
                  element={AdminFunctions}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Admin Functions - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/locations"
              element={
                <PrivateRoute
                  element={Locations}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Locations - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/applicant_agent/company"
              element={
                <PrivateRoute
                  element={Company}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Applicant/Agent Organization - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/applicant_agent/form"
              element={
                <PrivateRoute
                  element={AgentApplicantForm}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Applicant/Agent Form - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/verification/referee_details"
              element={
                <PrivateRoute
                  element={RefereeDetails}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Referee Details - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/verification/RTO_details"
              element={
                <PrivateRoute
                  element={RTODetails}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="RTO Details - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/dashboard/view_application"
              element={
                <PrivateRoute
                  element={ViewUpdate}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="View Application - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/application_manager/view_application"
              element={
                <PrivateRoute
                  element={ViewUpdate}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="View Application - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/application_manager/view_application/transfer_applicant"
              element={
                <PrivateRoute
                  element={ApplicantTransfer}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Transfer Applicant - AQATO Admin Portal"
                />
              }
            />
            <Route
              path="/admin/application_manager/view_application/transfer_applicant/preview"
              element={
                <PrivateRoute
                  element={ApplicantTransferPreview}
                  loggedIn={loggedIn}
                  onLogout={handleLogout}
                  title="Transfer Applicant Preview - AQATO Admin Portal"
                />
              }
            />
            {/* Catch-all route for unauthorized access */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
