import React, { useState, useEffect, useRef } from "react";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "react-bootstrap";
import { getPracticalBookingDataAPI } from "../../api";
import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import { FaCaretDown } from "react-icons/fa";
import Reschedule from "./Reschedule/Reschedule";
import CancelInterview from "./CancelInterview/CancelInterview";
import NewBookingModal from "./NewBookingModal/NewBookingModal";
import Loader from "../Widgets/Loader/Loader";

const PracticalBookings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [ExtraData, setExtraData] = useState();

  // const [searchQuery, setSearchQuery] = useState("");
  // const [dataPerPageButton, setDataPerPageButton] = useState("10");
  // const [currentPage, setCurrentPage] = useState(1);

  // Initialize state from localStorage or default values
  const [searchQuery, setSearchQuery] = useState(() => {
    const storedValue = localStorage.getItem("practicalBookings_searchQuery");
    return storedValue || "";
  });

  const [dataPerPageButton, setDataPerPageButton] = useState(() => {
    const storedValue = localStorage.getItem(
      "practicalBookings_dataPerPageButton"
    );
    return storedValue ? JSON.parse(storedValue) : "10";
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const storedValue = localStorage.getItem("practicalBookings_currentPage");
    return storedValue ? JSON.parse(storedValue) : 1;
  });

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("practicalBookings_searchQuery", searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    localStorage.setItem(
      "practicalBookings_dataPerPageButton",
      JSON.stringify(dataPerPageButton)
    );
  }, [dataPerPageButton]);

  useEffect(() => {
    localStorage.setItem(
      "practicalBookings_currentPage",
      JSON.stringify(currentPage)
    );
  }, [currentPage]);

  const debounceTimeout = useRef(null);

  // const handleSearch = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  const handleSearch = (event) => {
    localStorage.setItem("startPage_admin_practical_booking", 1);
    setStartPage(1);

    setSearchQuery(event.target.value);
    const searchInputByUser = event.target.value;

    // Prepare form data
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchInputByUser);
    formDataApplicationsRecords.append("itemsPerPage", "10");
    formDataApplicationsRecords.append("page", "1");

    // Debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getPracticalBookingDataFunction(formDataApplicationsRecords);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  // const handleDataPerPageChange = (e) => {
  //   const newDataPerPage = e.target.value;
  //   setDataPerPageButton(newDataPerPage);

  //   const formdata = new FormData();
  //   formdata.append("page", currentPage);
  //   formdata.append("itemsPerPage", newDataPerPage);

  //   getPracticalBookingDataFunction(formdata);
  // };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);

    const rowLength = event.target.value;
    const formDataApplicationsRecods = new FormData();
    formDataApplicationsRecods.append("search_input", searchQuery);

    // formDataApplicationsRecods.append("itemsPerPage", rowLength);
    // formDataApplicationsRecods.append("page", currentPage);

    formDataApplicationsRecods.append("itemsPerPage", rowLength);
    formDataApplicationsRecods.append("page", 1);

    localStorage.setItem("practicalBookings_currentPage", 1);

    setCurrentPage(1);

    localStorage.setItem("startPage_admin_practical_booking", 1);
    setStartPage(1);

    getPracticalBookingDataFunction(formDataApplicationsRecods);
  };

  const [showRescheduleModal, setRescheduleShow] = useState(false);

  const handleRescheduleClose = () => {
    setRescheduleShow(false);
    setSelectedItem(null);
  };

  const handleRescheduleShow = (item) => {
    setRescheduleShow(true);
    setSelectedItem(item);
  };

  const [showCancelInterviewModal, setCancelInterviewShow] = useState(false);

  const handleCancelInterviewClose = () => {
    setCancelInterviewShow(false);
    setSelectedItem(null);
  };

  const handleCancelInterviewShow = (item) => {
    setCancelInterviewShow(true);
    setSelectedItem(item);
  };

  const [showNewInterviewModal, setNewInterviewModalShow] = useState(false);

  const handleNewInterviewModalClose = () => {
    setNewInterviewModalShow(false);
    setSelectedItem(null);
  };

  const handleNewInterviewModalShow = () => setNewInterviewModalShow(true);

  const [originalData, setOriginalData] = useState([]);

  const getPracticalBookingDataFunction = async (data) => {
    try {
      setLoading(true);

      const response = await getPracticalBookingDataAPI(data);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOriginalData(
          response?.data?.response?.data?.pratical_bookings || []
        );

        setExtraData(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("search_input", searchQuery);

    getPracticalBookingDataFunction(formdata);
  }, []);

  // const handlePrevious = () => {
  //   // Only decrement the current page if it's greater than 1
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }

  //   // Determine the previous page number, ensuring it doesn't go below 0
  //   const currentPreviousPageNo = currentPage > 1 ? currentPage - 1 : 0;

  //   // Prepare form data
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", currentPreviousPageNo);

  //   // Call the function to get the application manager data
  //   getPracticalBookingDataFunction(formDataApplicationsRecords);
  // };

  // const handleNext = () => {
  //   setCurrentPage(currentPage + 1);

  //   const currentNextPageNo = currentPage + 1;

  //   // Prepare form data
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", currentNextPageNo);

  //   getPracticalBookingDataFunction(formDataApplicationsRecords);
  // };

  // const handlePrevious = () => {
  //   if (currentPage > 1) {
  //     const currentPreviousPageNo = currentPage - 1;
  //     setCurrentPage(currentPreviousPageNo);
  //     fetchPageData(currentPreviousPageNo);
  //   }
  // };

  // const handleNext = () => {
  //   if (currentPage < totalPages) {
  //     const currentNextPageNo = currentPage + 1;
  //     setCurrentPage(currentNextPageNo);
  //     fetchPageData(currentNextPageNo);
  //   }
  // };

  // const totalPages = Math.ceil(ExtraData?.totalRows / dataPerPageButton);
  // const [startPage, setStartPage] = useState(1);
  // const visiblePages = 4;

  // const handlePageClick = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   fetchPageData(pageNumber);
  // };

  // const handleFirst = () => {
  //   // Set the current page to 1
  //   setCurrentPage(1);
  //   fetchPageData(1);

  //   // Adjust startPage to 1 to reset the visible page range
  //   setStartPage(1);
  // };

  // const handleLast = () => {
  //   const lastPage = totalPages;
  //   setCurrentPage(lastPage);
  //   fetchPageData(lastPage);

  //   // Adjust start page to show the last set of pages
  //   const newStartPage = Math.max(1, totalPages - visiblePages + 1);
  //   setStartPage(newStartPage);
  // };

  // const handlePrev = () => {
  //   if (startPage > 1) {
  //     setStartPage(Math.max(1, startPage - visiblePages)); // Ensure startPage doesn't go below 1
  //   }
  // };

  // const handleNext = () => {
  //   if (startPage + visiblePages <= totalPages) {
  //     setStartPage(startPage + visiblePages); // Increase by the number of visible pages
  //   }
  // };

  // const fetchPageData = (pageNumber) => {
  //   // Prepare form data for the selected page number
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", pageNumber);

  //   // Call the function to get the application manager data
  //   getPracticalBookingDataFunction(formDataApplicationsRecords);
  // };

  const totalPages = Math.ceil(ExtraData?.totalRows / dataPerPageButton);
  const visiblePages = 4;

  const [startPage, setStartPage] = useState(() => {
    const savedStartPage = localStorage.getItem(
      "startPage_admin_practical_booking"
    );
    return savedStartPage ? Number(savedStartPage) : 1;
  });

  useEffect(() => {
    fetchPageData(currentPage);
    // Update local storage when currentPage or startPage changes
    // localStorage.setItem('practicalBookings_currentPage', currentPage);
    localStorage.setItem("startPage_admin_practical_booking", startPage);
  }, [currentPage, startPage]);

  const fetchPageData = (pageNumber) => {
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchQuery);
    formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecords.append("page", pageNumber);

    // Your data fetching function
    getPracticalBookingDataFunction(formDataApplicationsRecords);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFirst = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const handleLast = () => {
    const lastPage = totalPages;
    setCurrentPage(lastPage);
    const newStartPage = Math.max(1, lastPage - visiblePages + 1);
    setStartPage(newStartPage);
  };

  const handlePrev = () => {
    if (startPage > 1) {
      const newStartPage = Math.max(1, startPage - visiblePages);
      setStartPage(newStartPage);
      setCurrentPage(newStartPage);
    }
  };

  const handleNext = () => {
    if (startPage + visiblePages <= totalPages) {
      const newStartPage = startPage + visiblePages;
      setStartPage(newStartPage);
      setCurrentPage(newStartPage);
    }
  };

  // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };

  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();

    // Open the URL in a new tab with pointerID as a query parameter
    window.open(
      `/admin/application_manager/view_application?pointerID=${encodeURIComponent(
        id
      )}`,
      "_blank"
    );
  };

  const fetchPracticalBookingData = () => {
    const formdata = new FormData();
    formdata.append("page", currentPage);
    formdata.append("itemsPerPage", dataPerPageButton);
    formdata.append("search_input", searchQuery);

    // Call the API function
    getPracticalBookingDataFunction(formdata);
  };

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        {/* {loading && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )} */}

        {loading && <Loader />}

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Practical Bookings
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <Card.Body>
                  <div className="action_buttons_top bg-white action_btn_desk">
                    <Row className="my-2 mx-2 align-items-center justify-content-between">
                      <Col xs="auto" className="d-flex align-items-center pe-0">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "60px",
                          }}
                        >
                          <select
                            value={dataPerPageButton}
                            onChange={handleDataPerPageChange}
                            style={{
                              width: "100%",
                              padding: "6px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                              appearance: "none", // Remove default arrow
                              WebkitAppearance: "none", // Safari compatibility
                              MozAppearance: "none", // Firefox compatibility
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <FaCaretDown
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translateY(-50%)",
                              pointerEvents: "none", // Prevent interaction with the icon
                              fontSize: "14px",
                              color: "#333",
                            }}
                          />
                        </div>
                      </Col>

                      {/* Search input */}
                      <Col xs={5} className="d-flex align-items-center pe-0">
                        <Form.Control
                          type="search"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={handleSearch}
                          className="searchInput"
                        />
                      </Col>

                      {/* New Booking Button */}
                      <Col
                        xs={3}
                        className="d-flex align-items-center text-end"
                      >
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#edit_form2"
                          className="btn btn-sm btn_green_yellow"
                          onClick={handleNewInterviewModalShow}
                          style={{ padding: "8px", width: "100%" }}
                        >
                          <i className="bi bi-plus"></i> New Practical Booking
                        </button>
                      </Col>
                    </Row>
                  </div>

                  <div
                    id="table_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-12">
                          <div class="table-responsive table-wrapper">
                            <table
                              class="table table-striped table-hover dataTable no-footer"
                              style={{ cursor: "default" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{ width: "7%" }}
                                    className="th_font_size"
                                  >
                                    Sr. No.
                                  </th>
                                  <th
                                    style={{ width: "15%" }}
                                    className="th_font_size"
                                  >
                                    Application No.
                                  </th>
                                  <th
                                    style={{ width: "16.67%" }}
                                    className="th_font_size"
                                  >
                                    Applicant Name
                                  </th>
                                  <th
                                    style={{ width: "15.67%" }}
                                    className="th_font_size"
                                  >
                                    Occupation
                                  </th>
                                  <th
                                    style={{ width: "10%" }}
                                    className="th_font_size"
                                  >
                                    Country
                                  </th>
                                  <th
                                    style={{ width: "8.33%" }}
                                    className="th_font_size"
                                  >
                                    Location
                                  </th>
                                  <th
                                    style={{ width: "8.33%" }}
                                    className="th_font_size"
                                  >
                                    Date
                                  </th>
                                  <th
                                    style={{ width: "8.33%" }}
                                    className="th_font_size"
                                  >
                                    Time
                                  </th>
                                  <th
                                    style={{ width: "19%" }}
                                    className="th_font_size"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {originalData.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={index % 2 === 0 ? "even" : "odd"}
                                  >
                                    <td className="td_font_size">
                                      {index + 1}.
                                    </td>
                                    <td className="td_font_size">
                                      {item?.application_no}
                                    </td>

                                    <td
                                      onClick={(event) =>
                                        handleNavigateToViewApplication(
                                          event,
                                          item?.pointer_id
                                        )
                                      }
                                      style={{
                                        color: "#055837",
                                        cursor: "pointer",
                                        fontSize: "17px",
                                      }}
                                      className="hover-underline"
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          transition:
                                            "transform 0.1s ease-in-out, text-decoration 0.1s ease-in-out",
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.transform =
                                            "scale(1.1)";
                                          e.target.style.textDecoration =
                                            "underline";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.transform = "scale(1)";
                                          e.target.style.textDecoration =
                                            "none";
                                        }}
                                      >
                                        {item?.applicant_name}
                                      </span>
                                    </td>
                                    <td className="td_font_size">
                                      {item?.occupation}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.country}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.location}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.date}
                                    </td>
                                    <td className="td_font_size">
                                      {item?.time}
                                    </td>
                                    <td className="btn-flex">
                                      <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit_form1"
                                        className="btn btn-sm btn_green_yellow ms-1"
                                        onClick={() =>
                                          handleRescheduleShow(item)
                                        }
                                      >
                                        <i className="bi bi-pencil-square"></i>
                                      </button>

                                      <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_req2"
                                        className="btn btn-sm btn-danger ms-1"
                                        onClick={() =>
                                          handleCancelInterviewShow(item)
                                        }
                                      >
                                        {/* <i className="bi bi-trash-fill"></i> */}
                                        <i className="bi bi-x"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="mb-3 thick-hr" />

                    <div className="pagination-container">
                      <div className="dataTables_info">
                        Showing {ExtraData?.currentPage} to{" "}
                        {ExtraData?.itemsPerPage} of {ExtraData?.totalRows}{" "}
                        entries
                      </div>

                      {/* <span 
  style={{
    background: 'linear-gradient(to bottom right, #fff9c4, #fff3e0)', 
    color: '#055837', 
    padding: '8px 12px',
    borderRadius: '5px',
    // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', 
    // fontWeight: 'bold',
    // fontSize: '16px',
    display: 'inline-block',
    cursor:"default"
  }}
>
  Active Page : {currentPage}
</span> */}

                      {/* 

<div className="pagination-buttons">

<button
  className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
  onClick={handleFirst}
  disabled={currentPage === 1}
  style={currentPage === 1 ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
>
  First
</button>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={`paginate_button ${startPage === 1 ? "disabled" : ""}`}
        onClick={handlePrev}
        disabled={startPage === 1}
        style={startPage === 1 ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        Previous
      </button>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
        {Array.from({ length: visiblePages }, (_, index) => {
          const pageNumber = startPage + index;
          if (pageNumber > totalPages) return null; // Do not render if out of bounds

          const isActive = currentPage === pageNumber;

          return (
            <button
              key={pageNumber}
              className={`paginate_button ${isActive ? "active" : ""}`}
              style={{
                display: 'inline-block',
                margin: '0 4px',
                backgroundColor: isActive ? "black" : "transparent",
                color: isActive ? "white" : "black",
              }}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        className={`paginate_button ${(startPage + visiblePages - 1 >= totalPages) ? "disabled" : ""}`}
        onClick={handleNext}
        disabled={startPage + visiblePages - 1 >= totalPages}
        style={startPage + visiblePages - 1 >= totalPages ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        Next
      </button>
    </div>

    <button
      className={`paginate_button ${currentPage === totalPages ? "disabled" : ""}`}
      onClick={handleLast}
      disabled={currentPage === totalPages}
      style={currentPage === totalPages ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
    >
      Last
    </button>
  </div> */}

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handleFirst}
                          disabled={currentPage === 1}
                        >
                          First
                        </button>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            className={`paginate_button ${
                              startPage === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrev}
                            disabled={startPage === 1}
                          >
                            Previous
                          </button>

                          <div
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              flex: 1,
                            }}
                          >
                            {Array.from(
                              { length: visiblePages },
                              (_, index) => {
                                const pageNumber = startPage + index;
                                if (pageNumber > totalPages) return null;

                                const isActive = currentPage === pageNumber;

                                return (
                                  <button
                                    key={pageNumber}
                                    className={`paginate_button ${
                                      isActive ? "active" : ""
                                    }`}
                                    style={{
                                      display: "inline-block",
                                      margin: "0 4px",
                                      backgroundColor: isActive
                                        ? "black"
                                        : "transparent",
                                      color: isActive ? "white" : "black",
                                    }}
                                    onClick={() => handlePageClick(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                );
                              }
                            )}
                          </div>

                          <button
                            className={`paginate_button ${
                              startPage + visiblePages - 1 >= totalPages
                                ? "disabled"
                                : ""
                            }`}
                            onClick={handleNext}
                            disabled={
                              startPage + visiblePages - 1 >= totalPages
                            }
                          >
                            Next
                          </button>
                        </div>

                        <button
                          className={`paginate_button ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                          onClick={handleLast}
                          disabled={currentPage === totalPages}
                        >
                          Last
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <Reschedule
        showModal={showRescheduleModal}
        handleClose={handleRescheduleClose}
        selectedItem={selectedItem}
        fetchPracticalBookingData={fetchPracticalBookingData}
      />
      <CancelInterview
        showModal={showCancelInterviewModal}
        handleClose={handleCancelInterviewClose}
        selectedItem={selectedItem}
        fetchPracticalBookingData={fetchPracticalBookingData}
      />
      <NewBookingModal
        showModal={showNewInterviewModal}
        handleClose={handleNewInterviewModalClose}
        fetchPracticalBookingData={fetchPracticalBookingData}
      />
    </>
  );
};

export default PracticalBookings;
